import React from 'react';
import { BrowserRouter , Route, Routes } from 'react-router-dom';
import Home from './components/home';
import AboutUs from './Pages/AboutUs/index';
import Services from './Pages/Services/index';
import ContactUs from './Pages/ContactUs/index';
import Qatar from './components/QatarPage/index';
import Dubia from './components/DubaiPage/index';
import KSA from './components/SKAPage/index';
import './App.css';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/services" element={<Services />} />
        <Route path="/qatar" element={<Qatar />} />
        <Route path="/dubai" element={<Dubia />} />
        <Route path="/ksa" element={<KSA />} />
        <Route path="/contactus" element={<ContactUs />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
