import React, { Fragment } from 'react'
import Services from '../../components/ServicesReel/index'
import Navbar from '../../components/Navbar/index'
import Footer from '../../components/FooterSection/index'

const index = () => {
  return (
    <Fragment>
      <Navbar/>
      <Services/>
      <Footer/>

    </Fragment>
  )
}

export default index