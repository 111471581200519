import React, { Fragment } from 'react'
import AboutUs from '../../components/About/index'
import Navbar from '../../components/Navbar/index'
import Footer from '../../components/FooterSection/index'
const index = () => {
  return (
    <Fragment>
        <Navbar/>
        <AboutUs/>
        <Footer/>
    </Fragment>
  )
}

export default index