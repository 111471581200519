import React, { Fragment } from 'react'
import { Container, Row, Col, Image, Button, Form } from 'react-bootstrap';
import { FaTwitter, FaFacebookF, FaLinkedinIn, FaInstagram } from 'react-icons/fa';
import team1 from '../../assets/img/team-1.jpg'
import team2 from '../../assets/img/team-2.jpg'
import team3 from '../../assets/img/team-3.jpg'
import team4 from '../../assets/img/team-4.jpg'
const index = () => {
    const teamMembers = [
        {
            name: 'Olivia Mia',
            role: 'Spa & Beauty Expert',
            image: team1,
            social: {
                twitter: '#',
                facebook: '#',
                linkedin: '#',
                instagram: '#'
            }
        },
        {
            name: 'Cory Brown',
            role: 'Spa & Beauty Expert',
            image: team2,
            social: {
                twitter: '#',
                facebook: '#',
                linkedin: '#',
                instagram: '#'
            }
        },
        {
            name: 'Elizabeth Ross',
            role: 'Spa & Beauty Expert',
            image: team3,
            social: {
                twitter: '#',
                facebook: '#',
                linkedin: '#',
                instagram: '#'
            }
        },
        {
            name: 'Kelly Walke',
            role: 'Spa & Beauty Expert',
            image: team4,
            social: {
                twitter: '#',
                facebook: '#',
                linkedin: '#',
                instagram: '#'
            }
        }
    ];
      
  return (
    <Fragment>
<div className="container-fluid py-5">
        <Container>
            <Row className="justify-content-center text-center">
                <Col lg={6}>
                    <h6 className="d-inline-block bg-light text-primary text-uppercase py-1 px-2">Spa Specialist</h6>
                    <h1 className="mb-5 text-color">Spa & Beauty Specialist</h1>
                </Col>
            </Row>
            <Row>
                {teamMembers.map((member, index) => (
                    <Col lg={3} md={6} key={index}>
                        <div className="team position-relative overflow-hidden mb-5">
                            <img className="img-fluid" src={member.image} alt={member.name} />
                            <div className="position-relative text-center">
                                <div className="team-text  text-color" style={{ backgroundColor: '#fad2ca' }}>
                                    <h5 className="text-color text-uppercase">{member.name}</h5>
                                    <p className="m-0">{member.role}</p>
                                </div>
                                {/* <div className="team-social bg-dark text-center ">
                                    {Object.keys(member.social).map((platform, idx) => (
                                        <a
                                            key={idx}
                                            className="btn btn-outline-primary btn-square mr-2"
                                            href={member.social[platform]}
                                        >
                                            <i className={`fab fa-${platform}`}></i>
                                        </a>
                                    ))}
                                </div> */}
                            </div>
                        </div>
                    </Col>
                ))}
            </Row>
        </Container>
    </div>
    </Fragment>
  )
}

export default index