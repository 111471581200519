import React, { Fragment } from 'react';
import { Container, Row, Col, Image ,ListGroup} from 'react-bootstrap';
import aboutimg1 from '../../assets/Videos/About Us.jpg';

const Index = () => {
  return (
    <Fragment>
      {/* About Start */}
      <Container fluid className="py-5 bg-light">
        <Container className="py-5">
          <Row className="">
            <Col lg={6} className="pb-5 pb-lg-0">
              <Image src={aboutimg1} fluid />
            </Col>
            <Col lg={6}>
              <h6 className="d-inline-block text-primary text-uppercase bg-light py-1 px-2" >About Us</h6>
              <h1 className="mb-4 text-color">Welcome to Healing Hands</h1>
              <p className="pl-4 border-left border-primary">
                 Your trusted partner in relaxation and rejuvenation. We are dedicated to providing premium home massage services exclusively for women and couples across Qatar, Dubai, and KSA.
              </p>
              <p>
                At Healing Hands, we believe in the power of touch to heal, soothe, and restore balance in your life. Our team of professional therapists is committed to delivering personalized massage experiences that cater to your unique needs, all in the comfort and privacy of your own home.
              </p>
              <Row className="pt-3">
                <Col sm={6}>
                  <div className="bg-light text-center p-4">
                    <h3 className="display-4 text-primary">99</h3>
                    <h6 className="text-uppercase">Spa Specialists</h6>
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="bg-light text-center p-4">
                    <h3 className="display-4 text-primary">999</h3>
                    <h6 className="text-uppercase">Happy Clients</h6>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Container>
      {/* About End */}

      {/* Our Mission Start */}
      <Container fluid className="py-5 bg-light mt-3">
        <Container>
          <h2 className="text-center mb-5 text-color">Our Mission</h2>
          <p className="text-center">
            Our mission is to bring wellness to your doorstep, offering a sanctuary of peace and tranquility amidst the hustle and bustle of daily life. We strive to create an environment where you can unwind, de-stress, and reconnect with yourself or your loved one through the healing power of massage.
          </p>
        </Container>
      </Container>
      {/* Our Mission End */}

      {/* Why Choose Us Start */}
      <Container fluid className="py-5  bg-light mt-5">
        <Container>
          <h2 className="text-center mb-5 text-color">Why Choose Us?</h2>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <h5 className="text-primary">Professional Excellence</h5>
              <p>
                Our licensed and experienced therapists are skilled in a variety of massage techniques, ensuring a customized session tailored to your preferences.
              </p>
            </ListGroup.Item>
            <ListGroup.Item>
              <h5 className="text-primary">Safety and Comfort</h5>
              <p>
                Your safety and comfort are our top priorities. We adhere to the highest standards of hygiene and professionalism, ensuring a safe and relaxing experience every time.
              </p>
            </ListGroup.Item>
            <ListGroup.Item>
              <h5 className="text-primary">Convenience</h5>
              <p>
                We bring the spa experience to you, eliminating the need for travel and allowing you to enjoy a luxurious massage in the comfort of your own home.
              </p>
            </ListGroup.Item>
            <ListGroup.Item>
              <h5 className="text-primary">Tailored Services</h5>
              <p>
                Whether you’re seeking relaxation, stress relief, or a therapeutic treatment, our services are designed to meet your specific needs.
              </p>
            </ListGroup.Item>
          </ListGroup>
        </Container>
      </Container>
      {/* Why Choose Us End */}
    </Fragment>
  );
};

export default Index;
