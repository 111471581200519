import React, { Fragment, useState } from 'react';
import { Container, Row, Col, Button, Navbar, NavDropdown } from 'react-bootstrap';

function Index() {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [showLocations, setShowLocations] = useState(false);
  
  const plans = [
    {
      price: 'Was 300 AD now 280 AD',
      plan: 'Bliss massage',
      features: [
        'For 1 hour',
        'Full Body Massage',
        'Hot Stone Massage',
        "Thai Reflexology Massage"
        // 'Tissue Body Polish',
        // 'Foot & Nail Care',
      ],
    },
    {
      price: 'Was 399 AD now 345 AD',
      plan: 'Luxury massage',
      features: [
        'For 2 hours',
        'Full Body Massage',
        'Hot Stone Massage',
        "Thai Reflexology Massage"
        // 'Tissue Body Polish',
        // 'Foot & Nail Care',
      ],
    },
    {
      price: 'Was 499 AD now 399 AD',
      plan: 'Paradise massage',
      features: [
        'For 1 hour',
        'Full Body Massage',
        'Hot Stone Massage',
        "Thai Reflexology Massage"
        // 'Tissue Body Polish',
        // 'Foot & Nail Care',
      ],
    },
  ];

  // Function to handle the WhatsApp redirection based on location
  const handleWhatsAppRedirect = (location) => {
    let whatsappNumber = '';
    switch (location) {
      case 'Qatar':
        whatsappNumber = 'https://wa.me/+97470430642'; // Replace with Qatar WhatsApp link
        break;
      case 'Dubai':
        whatsappNumber = 'https://wa.me/+971555151328'; // Replace with Dubai WhatsApp link
        break;
      case 'KSA':
        whatsappNumber = 'https://wa.me/+971555151328'; // Replace with KSA WhatsApp link
        break;
      default:
        whatsappNumber = 'https://wa.me/'; // Default link if needed
        break;
    }
    window.open(whatsappNumber, '_blank');
  };

  return (
    <Fragment>
      <div
        className="container-fluid bg-pricing"
        style={{ position: 'relative', margin: '90px 0' }}
      >
        <Container className="pricing-content">
          <Row>
            <Col lg={12} className="pt-5 pb-lg-5">
              <div className="pricing-text   p-4 p-lg-5 my-lg-5 "
               style={{backgroundColor:"#fad2ca"}}
               >
                <Row>
                  {plans.map((plan, index) => (
                    <Col md={4} key={index} className="mb-4">
                      <div className="bg-white">
                        <div className="d-flex align-items-center justify-content-between border-bottom border-primary p-4">
                          {/* <h1 className="display-4 mb-0">
                            <small
                              className="align-top text-muted font-weight-small"
                              style={{ fontSize: '16px', lineHeight: '40px' }}
                            >
                              {plan.price}
                            </small>
                          </h1> */}
                          <h5
                            className="text-color text-uppercase  m-0"
                            style={{ fontSize: '14px' }}
                          >
                            {plan.plan}
                          </h5>
                        </div>
                        <div className="p-4">
                          {plan.features.map((feature, featureIndex) => (
                            <p key={featureIndex} style={{ fontSize: '14px' }}>
                              <i className="fa fa-check text-success mr-2"></i>
                              {" "} {feature}
                            </p>
                          ))}
                          <Button
                            variant=""
                            className="d-none d-lg-block my-2"
                            style={{ backgroundColor: '#fa876f' }}
                            onClick={() => {
                              setSelectedPlan(plan.plan);
                              setShowLocations(true);
                            }}
                          >
                            Order Now
                          </Button>
                          {showLocations && selectedPlan === plan.plan && (
                            <NavDropdown
                              title="Select Location"
                              id="location-dropdown"
                              className="mt-2"
                              show={showLocations}
                            >
                              <NavDropdown.Item
                                onClick={() => handleWhatsAppRedirect('Qatar')}
                              >
                                Qatar
                              </NavDropdown.Item>
                              <NavDropdown.Item
                                onClick={() => handleWhatsAppRedirect('Dubai')}
                              >
                                Dubai
                              </NavDropdown.Item>
                              <NavDropdown.Item
                                onClick={() => handleWhatsAppRedirect('KSA')}
                              >
                                KSA
                              </NavDropdown.Item>
                            </NavDropdown>
                          )}
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
}

export default Index;
