import React, { Fragment } from 'react'
import { Container, Row, Col, Form, Navbar, Nav, Button, Image, Carousel, ListGroup } from 'react-bootstrap';
import img from '../../assets/img/carousel-1.jpg'
import img2 from '../../assets/img/carousel-2.jpg'
import img3 from '../../assets/img/carousel-3.jpg'
import img11 from '../../assets/Videos/5.jpg'
import img22 from '../../assets/Videos/9.jpg'
import img33 from '../../assets/Videos/5.jpg'
import { Link } from 'react-router-dom';
const index = () => {
    return (
        <Fragment>
            {/* About Start */}
            <div className="container-fluid p-0 mb-5 pb-5">
                <Carousel fade>
                    <Carousel.Item style={{ minHeight: '100vh' }}>
                        <img
                            className="d-block w-100 h-100"
                            src={img11}
                            alt="First slide"
                            style={{ objectFit: 'cover' }}
                        />
                        <Carousel.Caption className="d-flex flex-column align-items-center justify-content-center">
                            <div className="p-3" style={{ maxWidth: '900px' }}>
                                <h6 className="display-3 text-capitalize text-white" style={{ letterSpacing: '3px' }}>
                                    Healing Hands                     </h6>
                                <h3 className="text-white text-uppercase mb-3 animate__animated animate__fadeInDown mb-3">Massage Treatment</h3>
                                <p className="mx-md-5 px-5">
                                    At Healing Hands, we believe in the power of touch and the profound benefits it brings to the body, mind, and soul. Our mission is to provide high-quality, professional spa and massage services directly to your home, allowing you to enjoy the ultimate relaxation and rejuvenation without the hassle of travel. Whether you're looking to unwind after a long day, alleviate chronic pain, or simply indulge in some self-care, our skilled therapists are here to cater to your needs.                            </p>
                                <Link className="btn btn-outline-light py-3 px-4 mt-3 animate__animated animate__fadeInUp" to="/contactus">
                                    Make Appointment
                                </Link>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item style={{ minHeight: '100vh' }}>
                        <img
                            className="d-block w-100 h-100"
                            src={img22}
                            alt="Second slide"
                            style={{ objectFit: 'cover' }}
                        />
                        <Carousel.Caption className="d-flex flex-column align-items-center justify-content-center">
                            <div className="p-3" style={{ maxWidth: '900px' }}>
                                <h6 className="display-3 text-capitalize text-white " style={{ letterSpacing: '3px' }}>
                                    Healing Hands
                                </h6>
                                <h3 className="text-white text-uppercase mb-3 animate__animated animate__fadeInDown  mb-3">Wellness at Home</h3>
                                <p className="mx-md-5 px-5">
                                    Experience the ultimate relaxation and rejuvenation in the comfort of your own home. At Healing Hands, we bring the spa experience to your doorstep, offering a wide range of professional spa and massage services designed to soothe your body, mind, and spirit.                            </p>
                                <Link className="btn btn-outline-light py-3 px-4 mt-3 animate__animated animate__fadeInUp" to="/contactus">
                                    Make Appointment
                                </Link>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                    {/* <Carousel.Item style={{ minHeight: '100vh' }}>
                    <img
                        className="d-block w-100 h-100"
                        src={img33}
                        alt="Third slide"
                        style={{ objectFit: 'cover' }}
                    />
                    <Carousel.Caption className="d-flex flex-column align-items-center justify-content-center">
                        <div className="p-3" style={{ maxWidth: '900px' }}>
                            <h6 className="text-white text-uppercase mb-3 animate__animated animate__fadeInDown" style={{ letterSpacing: '3px' }}>
                            Welcome to Healing Hands                             </h6>
                            <h3 className="display-3 text-capitalize text-white mb-3">Cellulite Treatment</h3>
                            <p className="mx-md-5 px-5">
                                Lorem rebum magna dolore amet lorem eirmod magna erat diam stet. Sadips duo stet amet amet ndiam elitr ipsum labore diam
                            </p>
                            <a className="btn btn-outline-light py-3 px-4 mt-3 animate__animated animate__fadeInUp" href="/contactus">
                                Make Appointment
                            </a>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item> */}
                </Carousel>
            </div>
            {/* About End */}

        </Fragment>
    )
}

export default index