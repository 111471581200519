import React, { Fragment, useState } from 'react';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';

import Aromatherapy from '../../assets/Videos/AromaTherapy Massage.mp4'
import Lymphatic from '../../assets/Videos/Lamphatic Drianage Massage.mp4'
import Oil from '../../assets/Videos/oil massage.mp4'
import Swedish from '../../assets/Videos/SwedishMassageReel.mp4'
import Thai from '../../assets/Videos/Thai Reflexology Massage.mp4'
import Maternity from '../../assets/Videos/Maternity Massage.mp4'




// import Swedish from '../../assets/img/Swedish Massage.jpg';
// import Lymphatic from '../../assets/img/Lymphatic Drainage Massage.jpg';
// import Thai from '../../assets/img/Thai Reflexology.jpg';
// import Aromatherapy from '../../assets/img/Aromatherapy Massage.jpg';
// import Maternity from '../../assets/img/Maternity Massage.jpg';
// import Oil from '../../assets/img/Oil Massage.jpg';


const Index = () => {
    const [showModal, setShowModal] = useState(false);
    const [selectedService, setSelectedService] = useState(null);

    const services = [
        {
            image: Lymphatic,
            title: 'Lymphatic Drainage Massage',
            litlebit: 'A technique designed to enhance the flow of lymphatic fluid... ',
            description: (
                <div>
                    <h4>Techniques</h4>
                    <ul>
                        <li><strong>Light Pressure</strong>: Gentle pressure is applied to stimulate the lymphatic system, as the lymphatic vessels are close to the skin's surface and are sensitive to pressure.</li>
                        <li><strong>Pumping Movements</strong>: The therapist uses rhythmic, circular, and wave-like motions to encourage lymph flow.</li>
                        <li><strong>Directional Strokes</strong>: Techniques are used to follow the natural direction of lymphatic flow, moving from distal areas (like arms and legs) towards the central parts of the body (like the torso).</li>
                    </ul>

                    <h4>Benefits</h4>
                    <ul>
                        <li><strong>Detoxification</strong>: Helps in the removal of metabolic waste and toxins from the body.</li>
                        <li><strong>Reduced Swelling</strong>: Effective in managing and reducing fluid retention and edema.</li>
                        <li><strong>Improved Circulation</strong>: Enhances overall blood and lymphatic circulation.</li>
                        <li><strong>Boosted Immune System</strong>: Supports immune function by facilitating the movement of lymph, which contains immune cells.</li>
                        <li><strong>Enhanced Relaxation</strong>: Can provide a calming effect and reduce stress.</li>
                    </ul>

                    <h4>Procedure</h4>
                    <ul>
                        <li><strong>Initial Assessment</strong>: The therapist will review your health history and discuss any specific concerns or areas needing attention.</li>
                        <li><strong>Massage Session</strong>: The client typically lies on a massage table, and the therapist uses light, rhythmic strokes to stimulate lymphatic flow.</li>
                        <li><strong>Post-Massage Care</strong>: The therapist may offer advice on hydration, gentle exercise, or lifestyle adjustments to maximize the benefits of the massage.</li>
                    </ul>

                    <h4>Considerations</h4>
                    <ul>
                        <li><strong>Medical Conditions</strong>: It's important to inform the therapist of any medical conditions, particularly those affecting the lymphatic system, such as lymphedema or chronic infections.</li>
                        <li><strong>Comfort Level</strong>: The therapist should use only light pressure to avoid causing discomfort, as excessive pressure can hinder lymphatic flow.</li>
                    </ul>

                    <p>Lymphatic drainage massage is beneficial for those dealing with fluid retention, post-surgical recovery, or those looking to support their immune system. It is generally considered a gentle, non-invasive treatment that can be incorporated into a regular wellness routine.</p>
                </div>
            )
        },

        {
            image: Thai,
            title: 'Thai Reflexology',
            litlebit: 'Thai reflexology is a traditional Thai therapeutic practice...',
            description: (
                <div>
                    <p>Thai reflexology is a traditional Thai therapeutic practice that combines elements of reflexology with aspects of Thai massage. It focuses on stimulating specific points on the feet and hands to promote overall health and well-being. Here’s a detailed overview:</p>

                    <p><strong>Techniques</strong></p>
                    <ol>
                        <li><strong>Foot Reflexology</strong>: Involves applying pressure to various points on the feet that correspond to different organs and systems in the body. This is believed to help balance the body's energy and improve health.</li>
                        <li><strong>Hand Reflexology</strong>: Similar to foot reflexology, but focuses on pressure points on the hands. It’s used to address issues in the corresponding areas of the body.</li>
                        <li><strong>Thai Massage Elements</strong>: May include gentle stretching, pressing, and acupressure techniques, incorporating elements of traditional Thai massage.</li>
                    </ol>

                    <p><strong>Benefits</strong></p>
                    <ul>
                        <li><strong>Relaxation</strong>: Promotes a deep state of relaxation and stress relief.</li>
                        <li><strong>Improved Circulation</strong>: Enhances blood flow in the feet and hands, which can improve overall circulation.</li>
                        <li><strong>Pain Relief</strong>: May help alleviate pain and discomfort in various parts of the body.</li>
                        <li><strong>Detoxification</strong>: Supports the body’s natural detoxification processes by stimulating reflex points.</li>
                        <li><strong>Energy Balance</strong>: Aims to balance the body's energy flow, which is a central concept in Thai healing practices.</li>
                    </ul>

                    <p><strong>Procedure</strong></p>
                    <ul>
                        <li><strong>Initial Consultation</strong>: The practitioner will discuss your health history, concerns, and preferences to tailor the session to your needs.</li>
                        <li><strong>Treatment Session</strong>: The client typically sits in a comfortable position while the practitioner works on the feet and/or hands, applying pressure to specific reflex points.</li>
                        <li><strong>Integration with Thai Massage</strong>: The session may include stretches or acupressure techniques to enhance the reflexology benefits.</li>
                    </ul>

                    <p><strong>Considerations</strong></p>
                    <ul>
                        <li><strong>Health Conditions</strong>: Inform the practitioner of any health issues, such as diabetes or circulation problems, as reflexology involves applying pressure to sensitive areas.</li>
                        <li><strong>Comfort Level</strong>: Reflexology should be applied with appropriate pressure, avoiding excessive discomfort.</li>
                    </ul>

                    <p>Thai reflexology blends reflexology principles with traditional Thai massage techniques, offering a holistic approach to relaxation and health. It is valued for its potential benefits in stress reduction, pain relief, and overall well-being.</p>
                </div>
            ),
        },
        {
            image: Aromatherapy,
            title: 'Aromatherapy Massage',
            litlebit: 'A therapeutic properties of essential oils with traditional...',
            description: (
                <div>
                    <h4>Techniques</h4>
                    <ul>
                        <li><strong>Essential Oils</strong>: Pure plant extracts are used, each with its own therapeutic properties (e.g., lavender for relaxation, eucalyptus for respiratory support).</li>
                        <li><strong>Blending</strong>: Essential oils are often diluted with a carrier oil (such as jojoba or almond oil) to ensure safe application and enhance their benefits.</li>
                        <li><strong>Massage Techniques</strong>: Various massage strokes (e.g., effleurage, petrissage) are applied using the aromatic oils to help with relaxation and muscle tension.</li>
                    </ul>

                    <h4>Benefits</h4>
                    <ul>
                        <li><strong>Relaxation</strong>: Essential oils can help reduce stress and promote a sense of calm.</li>
                        <li><strong>Enhanced Mood</strong>: Aromas can influence emotional well-being, potentially improving mood and mental clarity.</li>
                        <li><strong>Pain Relief</strong>: Certain essential oils have anti-inflammatory properties that may help alleviate muscle and joint pain.</li>
                        <li><strong>Improved Sleep</strong>: Oils like lavender can aid in better sleep quality and address insomnia.</li>
                        <li><strong>Boosted Circulation</strong>: Massage techniques combined with essential oils can enhance blood flow and reduce muscle tension.</li>
                    </ul>

                    <h4>Procedure</h4>
                    <ul>
                        <li><strong>Consultation</strong>: The therapist discusses your health history, preferences, and any specific concerns or goals.</li>
                        <li><strong>Oil Selection</strong>: Based on your needs, the therapist selects appropriate essential oils and blends them with a carrier oil.</li>
                        <li><strong>Massage Session</strong>: The client receives a massage using the selected oils, with techniques tailored to address individual concerns.</li>
                        <li><strong>Post-Massage Advice</strong>: The therapist may provide recommendations on essential oil use at home and hydration.</li>
                    </ul>

                    <h4>Considerations</h4>
                    <ul>
                        <li><strong>Allergies</strong>: It’s important to discuss any allergies or sensitivities to essential oils with the therapist.</li>
                        <li><strong>Pregnancy and Health Conditions</strong>: Certain oils may not be suitable for pregnant individuals or those with specific health conditions.</li>
                        <li><strong>Dilution</strong>: Essential oils should always be diluted with a carrier oil to avoid skin irritation.</li>
                    </ul>

                    <p>Aromatherapy massage offers a holistic approach to relaxation and wellness, integrating the physical benefits of massage with the therapeutic effects of essential oils.</p>
                </div>
            )
        },

        {
            image: Swedish,
            title: 'Swedish Massage',
            litlebit: 'Swedish massage is known for its gentle, relaxing approach...',
            description: (
                <div>
                    <h4>Techniques</h4>
                    <ul>
                        <li><strong>Effleurage</strong>: Long, sweeping strokes used to warm up the muscles and increase circulation.</li>
                        <li><strong>Petrissage</strong>: Kneading and lifting of the muscles to release tension and improve flexibility.</li>
                        <li><strong>Friction</strong>: Deep, circular movements applied to specific areas to break down adhesions and increase blood flow.</li>
                        <li><strong>Tapotement</strong>: Rhythmic tapping or percussion to stimulate the muscles and improve circulation.</li>
                        <li><strong>Vibration</strong>: Rapid shaking or trembling movements to relax muscles and release tension.</li>
                    </ul>

                    <h4>Benefits</h4>
                    <ul>
                        <li><strong>Relaxation</strong>: Reduces overall stress and promotes a state of calm.</li>
                        <li><strong>Improved Circulation</strong>: Enhances blood flow and helps in the removal of toxins from the body.</li>
                        <li><strong>Increased Flexibility</strong>: Helps in reducing muscle stiffness and increasing range of motion.</li>
                        <li><strong>Pain Relief</strong>: Alleviates muscle soreness and tension.</li>
                        <li><strong>Enhanced Immune Function</strong>: May boost the immune system by reducing stress and promoting better sleep.</li>
                    </ul>

                    <h4>Procedure</h4>
                    <ul>
                        <li><strong>Initial Consultation</strong>: The therapist will ask about your medical history and any specific concerns or areas of tension.</li>
                        <li><strong>Massage Session</strong>: The client lies on a massage table, and the therapist uses various strokes and techniques tailored to the client's needs.</li>
                        <li><strong>Post-Massage Advice</strong>: The therapist may provide advice on stretching, hydration, or follow-up sessions based on the client's response to the massage.</li>
                    </ul>

                    <h4>Considerations</h4>
                    <ul>
                        <li><strong>Health Conditions</strong>: It’s important to inform the therapist of any existing health conditions or injuries.</li>
                        <li><strong>Comfort Level</strong>: The therapist will adjust the pressure and techniques based on the client's comfort and preferences.</li>
                    </ul>

                    <p>Swedish massage is suitable for a wide range of individuals, from those seeking relaxation to those needing relief from muscle tension.</p>
                </div>
            )
        },

        {
            image: Maternity,
            title: 'Maternity Massage',
            litlebit: 'A therapeutic support and relieve discomfort during pregnancy...',
            description: (
                <div>
                    <h4>Techniques</h4>
                    <ul>
                        <li><strong>Gentle Strokes</strong>: Techniques are modified to accommodate the physical changes during pregnancy, focusing on gentle and soothing strokes.</li>
                        <li><strong>Positioning</strong>: The client may be positioned on their side or in a semi-reclining position with the use of supportive pillows to ensure comfort and safety.</li>
                        <li><strong>Targeted Areas</strong>: Focus is often on areas of common discomfort such as the lower back, hips, and legs.</li>
                    </ul>

                    <h4>Benefits</h4>
                    <ul>
                        <li><strong>Reduced Discomfort</strong>: Helps alleviate common pregnancy-related discomforts such as back pain, leg cramps, and muscle tension.</li>
                        <li><strong>Improved Circulation</strong>: Enhances blood flow, which can reduce swelling and improve overall circulation.</li>
                        <li><strong>Stress Relief</strong>: Promotes relaxation and helps manage stress and anxiety related to pregnancy.</li>
                        <li><strong>Better Sleep</strong>: May improve sleep quality by addressing discomfort and reducing stress.</li>
                        <li><strong>Enhanced Well-being</strong>: Can contribute to overall emotional and physical well-being during pregnancy.</li>
                    </ul>

                    <h4>Procedure</h4>
                    <ul>
                        <li><strong>Initial Consultation</strong>: The therapist will discuss any pregnancy-related concerns, health conditions, and preferences to tailor the session appropriately.</li>
                        <li><strong>Massage Session</strong>: The client is positioned comfortably, often using pillows for support. The therapist uses gentle techniques suited to the needs of the client.</li>
                        <li><strong>Safety Measures</strong>: Special attention is given to avoid pressure on certain areas and to ensure the safety of both the mother and the baby.</li>
                    </ul>

                    <h4>Considerations</h4>
                    <ul>
                        <li><strong>Healthcare Provider Consultation</strong>: It is important for the client to consult with their healthcare provider before starting massage therapy, especially if there are any complications or high-risk conditions.</li>
                        <li><strong>Therapist Certification</strong>: Ensure the therapist is trained in maternity or prenatal massage to ensure safe and appropriate techniques.</li>
                    </ul>

                    <p>Maternity massage offers physical and emotional support during pregnancy, helping to alleviate discomfort and promote relaxation.</p>
                </div>
            )
        },

        {
            image: Oil,
            title: 'Oil Massage',
            litlebit: 'Treat your face with our refreshing face masks...',
            description: 'Elitr labore sit dolor erat est lorem diam sea ipsum diam dolor duo sit ipsum',
        },
    ];

    const handleShowModal = (service) => {
        setSelectedService(service);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedService(null);
    };

    return (
        <Fragment>
            <Container fluid className="px-0 py-3 my-3 ">
                <Row className="mx-0 justify-content-center text-center">
                    <Col lg={6}>
                        <h6 className="d-inline-block bg-light text-primary text-uppercase py-1 px-2">Our Service</h6>
                        <h1 className='text-color'>Spa & Beauty Services</h1>
                    </Col>
                </Row>
                <Row className='p-0 m-0'>
                    {services.map((service, index) => (
                        <Col key={index} md={4}  className='mt-5'>
                            <div className="service-card">
                                {/* Video preview */}
                                <video width="100%" controls>
                                    <source src={service.image} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                                <div className="service-text text-center">
                                    <h4 className="text-color font-weight-medium px-3 py-3">{service.title}</h4>
                                    <p className="text-black p-2">{service.litlebit}</p>
                                    <Button variant="" style={{ backgroundColor: '#bc1fa7', marginBottom: "13px", color: "white" }} onClick={() => handleShowModal(service)}>Learn More</Button>
                                </div>

                            </div>
                        </Col>
                    ))}
                </Row>

            </Container>

            {/* Our Services Section Start */}
            <Container fluid className="py-5 bg-light">
                <Container>
                    <h2 className="text-center mb-5 text-color">Our Services</h2>
                    <p className="text-center">
                        We offer a range of massage therapies including Swedish, deep tissue, aromatherapy, and more. Each session is designed to enhance your well-being, leaving you feeling refreshed, revitalized, and ready to take on the world.
                    </p>
                    <p className="text-center">
                        Thank you for choosing Healing Hands. We look forward to being a part of your wellness journey.
                    </p>
                </Container>
            </Container>
            {/* Our Services Section End */}
            {/* Modal for service details */}
            <Modal show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{selectedService?.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {typeof selectedService?.description === 'string' ? (
                        <p>{selectedService?.description}</p>
                    ) : (
                        selectedService?.description
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default Index;
