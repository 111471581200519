import React, { Fragment } from 'react'
import Services from '../Services/index'
import { Container, Navbar, Nav, Button, Form, InputGroup, Row, Col, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import imagep from '../../assets/img/price qatar.jpg'
import NavbarBar from '../Navbar/index'
const index = () => {
  return (
    <Fragment>
      <NavbarBar/>
        <Services/>
        <Row className="m-0 p-0">
                  <Col sm={12}>
                    <h5 className="text-white text-uppercase mb-4">Our Pricing</h5>
                    <div className="price-image-container m-3">
                      <img
                        src={imagep}
                        alt="Pricing"
                        className="img-fluid"
                      />
                    </div>
                  </Col>
                </Row>
        <div>
                <div className="footer container-fluid position-relative bg-dark py-5" style={{ marginTop: '90px' }}>
                    <Container className="pt-5">
                        <Row>
                            <Col lg={6} className="pr-lg-5 mb-5 ">
                                <a href="#" className="navbar-brand">
                                    <h1 className="mb-3 text-color">Healing Hands</h1>
                                </a>
                                <p className='text-white'>
                                    We bring the spa to you, so you can unwind without leaving home.
                                    Professional Therapists: Our licensed and experienced therapists provide top-notch care
                                </p>
                                <p className='text-white'><i className="fa fa-map-marker-alt mr-2 text-white"></i> Qatar</p>
                                {/* <p className='text-white'><i className="fa fa-phone-alt mr-2 text-white"></i> +971 55 515 1328</p> */}
                                <p className='text-white'><i className="fa fa-envelope mr-2 text-white"></i> info@healinghands.com</p>
                                <div className="d-flex justify-content-start mt-4 gap-3">
                                    <a className="btn btn-lg button-bg btn-lg-square mr-2" href="https://www.facebook.com/HealingHandsQatar/"><i className="fab fa-facebook-f"></i></a>
                                    <a className="btn btn-lg button-bg btn-lg-square" href="https://www.instagram.com/healinghands_dubai/"><i className="fab fa-instagram"></i></a>
                                    <a className="btn btn-lg button-bg btn-lg-square mr-2" href="#"><i className="fab fa-youtube"></i></a>
                                    {/* <a className="btn btn-lg button-bg btn-lg-square mr-2" href="#"><i className="fab fa-linkedin-in"></i></a> */}
                                </div>
                            </Col>
                            <Col lg={6} className="pl-lg-5">
                                <Row>
                                    <Col sm={6} className="mb-5">
                                        <h5 className="text-white text-uppercase mb-4">Quick Links</h5>
                                        <div className="d-flex flex-column justify-content-start">
                                            <Link to="/" smooth={true} duration={500} className="text-white-50 mb-2"><i className="fa fa-angle-right mr-2"></i> Home</Link>
                                            <Link to="/aboutus" smooth={true} duration={500} className="text-white-50 mb-2"><i className="fa fa-angle-right mr-2"></i> About Us</Link>
                                            <Link to="/services" smooth={true} duration={500} className="text-white-50 mb-2"><i className="fa fa-angle-right mr-2"></i> Our Services</Link>
                                            {/* <Link to="/" smooth={true} duration={500} className="text-white-50 mb-2"><i className="fa fa-angle-right mr-2"></i>Pricing Plan</Link> */}
                                            <Link to="/contactus" smooth={true} duration={500} className="text-white-50"><i className="fa fa-angle-right mr-4"></i> Contact Us</Link>
                                        </div>
                                    </Col>
                                    <Col sm={6} className="mb-5">
                                        <h5 className="text-white text-uppercase mb-4">Our Services</h5>
                                        <div className="d-flex flex-column justify-content-start">
                                            <a className="text-white-50 mb-2" href="#"><i className="fa fa-angle-right mr-2"></i> Thai Massage</a>
                                            <a className="text-white-50 mb-2" href="#"><i className="fa fa-angle-right mr-2"></i> Deep Tissue Masseage</a>
                                            <a className="text-white-50 mb-2" href="#"><i class="fa fa-angle-right mr-2"></i> Swedish Massage</a>
                                            <a className="text-white-50 mb-2" href="#"><i className="fa fa-angle-right mr-2"></i> Reflexology Massage</a>
                                            <a className="text-white-50" href="#"><i className="fa fa-angle-right mr-2"></i> Lymphatic Draing Massage</a>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="container-fluid bg-dark text-light border-top py-4" style={{ borderColor: 'rgba(256, 256, 256, .15)' }}>
                    <Container>
                        <Row>
                            <Col md={6} className="text-center text-md-left mb-3 mb-md-0">
                                <p className="m-0 text-white">&copy; <a href="#">Healinghand.com</a>. All Rights Reserved.</p>
                            </Col>
                            <Col md={6} className="text-center text-md-right">
                                <p className="m-0 text-white">Designed by <a href="/">MI Solution Tech</a></p>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
    </Fragment>
  )
}

export default index