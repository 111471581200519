import React, { Fragment } from 'react'
import { Container, Row, Col, Form, Navbar, Nav, Button, Image, Carousel, ListGroup } from 'react-bootstrap';

const index = () => {
    return (
        <Fragment>
            <Container fluid className="custom-bg-color d-none d-lg-block">
                <Row className="py-2 px-lg-5">
                    <Col lg={6} className="text-left mb-2 mb-lg-0">
                        <div className="d-inline-flex align-items-center">
                            {/* <small><i className="fa fa-phone-alt mr-2"></i>Monady - Sunday (24/7) </small> */}
                            <small>Monady - Sunday (24/7) </small>
                            <small className="px-3">|</small>
                            <small><i className="fa fa-envelope mr-2"></i>info@Healinghand.com</small>
                        </div>
                    </Col>
                    <Col lg={6} className="text-right d-flex justify-content-end align-items-end">
                        <div className="d-flex align-items-end  justify-content-end">
                            <a className="text-primary px-2" href="https://www.facebook.com/HealingHandsQatar/"><i className="fab fa-facebook-f"></i></a>
                            <a className="text-primary px-2" href="https://www.instagram.com/healinghands_dubai/"><i className="fab fa-instagram"></i></a>
                            {/* <a className="text-primary px-2" href="#"><i className="fab fa-twitter"></i></a> */}
                            {/* <a className="text-primary px-2" href="#"><i className="fab fa-linkedin-in"></i></a> */}
                            <a className="text-primary pl-2" href="#"><i className="fab fa-youtube"></i></a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Fragment>)
}

export default index