import React, { useState } from 'react';
import { Container, Navbar, Nav, Button, Form, InputGroup, Row, Col, NavDropdown } from 'react-bootstrap';
import { Element } from 'react-scroll';
import { Link } from 'react-router-dom';

import About from '../About/index'
import Service from '../Services/index'
import OpenHours from '../OpenHours/index'
import Pricing from '../Pricing/index'
// import Navbar from '../Navbar/index'
import Topbar from '../Topbar/index'
import TeamSection from '../TeamSection/index'
import TestimonialSection from '../TestimonialSection/index'
import FooterSection from '../FooterSection/index';
import Carousel from '../Carousel/index';
import Video1 from '../../assets/Videos/Video1.mp4'

const Index = () => {
    const [showOptions, setShowOptions] = useState(false);

    const toggleOptions = () => {
        setShowOptions(!showOptions);
    };

    const openWhatsApp = (phoneNumber) => {
        console.log("Click");
        const isMobile = /Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        const whatsappUrl = isMobile
            ? `whatsapp://send?phone=${phoneNumber}` // Opens in mobile app if available
            : `https://web.whatsapp.com/send?phone=${phoneNumber}`; // Opens in browser
        window.open(whatsappUrl, '_blank');
    };
    return (
        <>
            {/* Topbar Start */}
            <Topbar />
            {/* Topbar End */}

            {/* Navbar Start */}
            <Container fluid className="p-0">
                <Navbar bg="white" expand="lg" className="py-3 py-lg-0 px-lg-5">
                    <Navbar.Brand href="index.html" className="ml-lg-3">
                        <h1 className="m-0 text-color">Healing Hands</h1>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarCollapse" />
                    <Navbar.Collapse id="navbarCollapse" className="justify-content-between px-lg-3">
                    <Nav className="m-auto py-0 d-flex align-items-center">
              <Link to="/" className="nav-link d-flex align-items-center">
                <h6 className="mb-0">Home</h6>
              </Link>
              <Link to="/aboutus" className="nav-link d-flex align-items-center">
                <h6 className="mb-0">About Us</h6>
              </Link>
              <Link to="/services" className="nav-link d-flex align-items-center">
                <h6 className="mb-0">Services</h6>
              </Link>
              <Link to="/contactus" className="nav-link d-flex align-items-center">
                <h6 className="mb-0">Contact Us</h6>
              </Link>
              <NavDropdown
                title={<span style={{ fontWeight: 600 }}>Locations</span>}
                id="basic-nav-dropdown"
                className="d-flex align-items-center"
              >
                <NavDropdown.Item ><Link to='/qatar'>Qatar</Link></NavDropdown.Item>
                <NavDropdown.Item ><Link to='/dubai'>Dubai</Link></NavDropdown.Item>
                <NavDropdown.Item ><Link to='/ksa'>KSA</Link></NavDropdown.Item>
              </NavDropdown>
            </Nav>
                        <Link to="contactus" className="text-decoration-none">
                            <Button variant="" className="d-none d-lg-block" style={{ backgroundColor: '#fa876f' }}>Book Now</Button>
                        </Link>
                    </Navbar.Collapse>
                </Navbar>
            </Container>
            {/* Navbar End */}

            {/* Sections Start */}
            <Carousel />

            <Element name="about">
                <About />
            </Element>

            <Element name="service">
                <Service />
            </Element>

            <Element name="pricing">
                <Pricing />
            </Element>
            <Element name="video">
                <Container className="my-5">
                    <h2 className="text-center mb-4">Watch Our Video</h2>
                    <div className="video-container" style={{ height: '400px', overflow: 'hidden' }}>
                        <video controls className="w-100 h-100" style={{ objectFit: 'cover' }}>
                            <source src={Video1} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </Container>
            </Element>


            {/* New Video Section End */}
            <Element name="contact">

            </Element>
            <TeamSection />
            <TestimonialSection />
            {/* <FooterSection /> */}
            {/* Footer Start */}
            <div>
                <div className="footer container-fluid position-relative bg-dark py-5" style={{ marginTop: '90px' }}>
                    <Container className="pt-5">
                        <Row>
                            <Col lg={6} className="pr-lg-5 mb-5 ">
                                <a href="#" className="navbar-brand">
                                    <h1 className="mb-3 text-color">Healing Hands</h1>
                                </a>
                                <p className='text-white'>
                                    We bring the spa to you, so you can unwind without leaving home.
                                    Professional Therapists: Our licensed and experienced therapists provide top-notch care
                                </p>
                                <p className='text-white'><i className="fa fa-map-marker-alt mr-2 text-white"></i> QATAR, Dubai,KSA</p>
                                {/* <p className='text-white'><i className="fa fa-phone-alt mr-2 text-white"></i> +971 55 515 1328</p> */}
                                <p className='text-white'><i className="fa fa-envelope mr-2 text-white"></i> info@healinghands.com</p>
                                <div className="d-flex justify-content-start mt-4 gap-3">
                                    <a className="btn btn-lg button-bg btn-lg-square mr-2" href="https://www.facebook.com/HealingHandsQatar/"><i className="fab fa-facebook-f"></i></a>
                                    <a className="btn btn-lg button-bg btn-lg-square" href="https://www.instagram.com/healinghands_dubai/"><i className="fab fa-instagram"></i></a>
                                    <a className="btn btn-lg button-bg btn-lg-square mr-2" href="#"><i className="fab fa-youtube"></i></a>
                                    {/* <a className="btn btn-lg button-bg btn-lg-square mr-2" href="#"><i className="fab fa-linkedin-in"></i></a> */}
                                </div>
                            </Col>
                            <Col lg={6} className="pl-lg-5">
                                <Row>
                                    <Col sm={6} className="mb-5">
                                        <h5 className="text-white text-uppercase mb-4">Quick Links</h5>
                                        <div className="d-flex flex-column justify-content-start">
                                            <Link to="/" smooth={true} duration={500} className="text-white-50 mb-2"><i className="fa fa-angle-right mr-2"></i> Home</Link>
                                            <Link to="/aboutus" smooth={true} duration={500} className="text-white-50 mb-2"><i className="fa fa-angle-right mr-2"></i> About Us</Link>
                                            <Link to="/services" smooth={true} duration={500} className="text-white-50 mb-2"><i className="fa fa-angle-right mr-2"></i> Our Services</Link>
                                            {/* <Link to="/" smooth={true} duration={500} className="text-white-50 mb-2"><i className="fa fa-angle-right mr-2"></i>Pricing Plan</Link> */}
                                            <Link to="/contactus" smooth={true} duration={500} className="text-white-50"><i className="fa fa-angle-right mr-4"></i> Contact Us</Link>
                                        </div>
                                    </Col>
                                    <Col sm={6} className="mb-5">
                                        <h5 className="text-white text-uppercase mb-4">Our Services</h5>
                                        <div className="d-flex flex-column justify-content-start">
                                            <a className="text-white-50 mb-2" href="#"><i className="fa fa-angle-right mr-2"></i> Thai Massage</a>
                                            <a className="text-white-50 mb-2" href="#"><i className="fa fa-angle-right mr-2"></i> Deep Tissue Masseage</a>
                                            <a className="text-white-50 mb-2" href="#"><i class="fa fa-angle-right mr-2"></i> Swedish Massage</a>
                                            <a className="text-white-50 mb-2" href="#"><i className="fa fa-angle-right mr-2"></i> Reflexology Massage</a>
                                            <a className="text-white-50" href="#"><i className="fa fa-angle-right mr-2"></i> Lymphatic Draing Massage</a>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="container-fluid bg-dark text-light border-top py-4" style={{ borderColor: 'rgba(256, 256, 256, .15)' }}>
                    <Container>
                        <Row>
                            <Col md={6} className="text-center text-md-left mb-3 mb-md-0">
                                <p className="m-0 text-white">&copy; <a href="#">Healinghand.com</a>. All Rights Reserved.</p>
                            </Col>
                            <Col md={6} className="text-center text-md-right">
                                <p className="m-0 text-white">Designed by <a href="/">MI Solution</a></p>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            {/* Sections End */}
            {/* WhatsApp Button */}
            <div className="whatsapp-button" onClick={toggleOptions}>
                <i className="fab fa-whatsapp"></i>
            </div>
            {showOptions && (
                <div className="whatsapp-options">
                    <Button variant="success" onClick={() => openWhatsApp('+971555151328')}>Dubai</Button>
                    <Button variant="success" onClick={() => openWhatsApp('+97470430642')}>Qatar</Button>
                    <Button variant="success" onClick={() => openWhatsApp('+971555151328')}>KSA</Button>
                </div>
            )}

            {/* Styles */}
            <style jsx>{`
                .whatsapp-button {
                    position: fixed;
                    bottom: 20px;
                    right: 20px;
                    background-color: #25d366;
                    color: white;
                    padding: 15px;
                    border-radius: 50%;
                    cursor: pointer;
                    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
                }

                .whatsapp-button i {
                    font-size: 24px;
                }

                .whatsapp-options {
                    position: fixed;
                    bottom: 80px;
                    right: 20px;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                }

                .whatsapp-options button {
                    background-color: #25d366;
                    color: white;
                    border: none;
                    padding: 10px;
                    border-radius: 5px;
                    cursor: pointer;
                    transition: background-color 0.3s;
                }

                .whatsapp-options button:hover {
                    background-color: #1eb747;
                }
            `}</style>
        </>
    );
};

export default Index;
