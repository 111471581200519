import React, { Fragment } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import testimonial1 from '../../assets/img/testimonial-1.jpg';
import testimonial2 from '../../assets/img/testimonial-2.jpg';
import testimonial3 from '../../assets/img/testimonial-3.jpg';
import ct from '../../assets/img/CT.jpg';

const Index = () => {
    const testimonials = [
        {
            image: testimonial1,
            name: 'Emily Johnson',
            profession: 'Profession 1',
            text: 'Fantastic service and attention to detail! The team delivered beyond my expectations. Highly recommended!',
            rating: 4
        },
        {
            image: testimonial2,
            name: 'Sophia Martinez',
            profession: 'Profession 2',
            text: 'Amazing experience! Professional and responsive team that provided exactly what I needed. Would hire again!',
            rating: 5
        },
        {
            image: testimonial3,
            name: 'Hannah Patel',
            profession: 'Profession 3',
            text: 'Top-notch results with great communication throughout. Truly impressed by their dedication and expertise!',
            rating: 3
        }
    ];

    return (
        <Fragment>
            <div className="container-fluid py-5 bg-light">
                <Container className="py-5 ">
                    <Row className="align-items-center mb-5">
                        <Col lg={6} className="pb-5 pb-lg-0">
                            <img className="img-fluid w-100" src={ct} alt="Testimonial Background" />
                        </Col>
                        <Col lg={6}>
                            <h1 className="mb-4 text-color">What Our Clients Say!</h1>
                            <Row>
                                {testimonials.map((testimonial, index) => (
                                    <Col lg={12} md={12} sm={12} className="mb-4" key={index}>
                                        <Card className="h-100 shadow-sm">
                                            <Card.Body>
                                                <div className="d-flex align-items-center mb-3">
                                                    <img
                                                        className="img-fluid rounded-circle"
                                                        src={testimonial.image}
                                                        style={{ width: '60px', height: '60px' }}
                                                        alt={testimonial.name}
                                                    />
                                                    <div className="ml-4 m-3 "> {/* Increased margin here for better spacing */}
                                                        <h6 className="text-uppercase mb-1">{testimonial.name}</h6>
                                                        {/* <small className="text-muted">{testimonial.profession}</small> */}
                                                        <div className="">
                                                            <strong>Rating:</strong> {Array.from({ length: testimonial.rating }, (_, i) => (
                                                                <i key={i} className="fa fa-star text-warning ml-1"></i>
                                                            ))}
                                                            {Array.from({ length: 5 - testimonial.rating }, (_, i) => (
                                                                <i key={i} className="fa fa-star text-secondary ml-1"></i>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                                <Card.Text>{testimonial.text}</Card.Text>
                                                {/* <div className="mt-3">
                          <strong>Rating:</strong> {Array.from({ length: testimonial.rating }, (_, i) => (
                            <i key={i} className="fa fa-star text-warning ml-1"></i>
                          ))}
                          {Array.from({ length: 5 - testimonial.rating }, (_, i) => (
                            <i key={i} className="fa fa-star text-secondary ml-1"></i>
                          ))}
                        </div> */}
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Fragment>
    );
};

export default Index;
