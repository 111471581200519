// src/components/contactus/ContactUs.js

import React, { Fragment, useState } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import Navbar from '../../components/Navbar/index';
import Footer from '../../components/FooterSection/index';

const ContactUs = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        description: '',
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formData);
        alert('Form submitted successfully!');
    };

    return (
        <Fragment>
            <Navbar />
            <Container className="contact-container d-flex justify-content-center align-items-center">
                <Form onSubmit={handleSubmit} className='w-50'>
                    <h2 className="text-center mb-4">Contact Us</h2>
                    <Form.Group controlId="formName" className="mb-3">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            placeholder="Enter your name"
                            required
                        />
                    </Form.Group>

                    <Form.Group controlId="formEmail" className="mb-3">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            placeholder="Enter your email"
                            required
                        />
                    </Form.Group>

                    <Form.Group controlId="formPhone" className="mb-3">
                        <Form.Label>Phone</Form.Label>
                        <Form.Control
                            type="text"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            placeholder="Enter your phone number"
                        />
                    </Form.Group>

                    <Form.Group controlId="formDescription" className="mb-3">
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                            as="textarea"
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            rows={4}
                            placeholder="Enter your message"
                        />
                    </Form.Group>

                    <Button  type="submit"  variant="" className="w-100 d-none d-lg-block " style={{ backgroundColor: '#fa876f',color:"white"}}>
                        Submit
                    </Button>
                </Form>
            </Container>
            <Footer />
        </Fragment>
    );
};

export default ContactUs;
