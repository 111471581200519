import React, { Fragment } from 'react';
import { Container, Navbar, Nav, Button, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function Index() {
  return (
    <Fragment>
      <Container fluid className="p-0">
        <Navbar expand="lg" className="py-3 py-lg-0 px-lg-5 custom-bg-color">
          <Navbar.Brand href="/" className="ml-lg-3">
            <h1 className="m-0 text-color">
              Healing Hands
            </h1>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarCollapse" />
          <Navbar.Collapse id="navbarCollapse" className="justify-content-between px-lg-3">
            <Nav className="m-auto py-0 d-flex align-items-center">
              <Link to="/" className="nav-link d-flex align-items-center">
                <h6 className="mb-0">Home</h6>
              </Link>
              <Link to="/aboutus" className="nav-link d-flex align-items-center">
                <h6 className="mb-0">About Us</h6>
              </Link>
              <Link to="/services" className="nav-link d-flex align-items-center">
                <h6 className="mb-0">Services</h6>
              </Link>
              <Link to="/contactus" className="nav-link d-flex align-items-center">
                <h6 className="mb-0">Contact Us</h6>
              </Link>
              <NavDropdown
                title={<span style={{ fontWeight: 600 }}>Locations</span>}
                id="basic-nav-dropdown"
                className="d-flex align-items-center"
              >
                <NavDropdown.Item ><Link to='/qatar'>Qatar</Link></NavDropdown.Item>
                <NavDropdown.Item ><Link to='/dubai'>Dubai</Link></NavDropdown.Item>
                <NavDropdown.Item ><Link to='/ksa'>KSA</Link></NavDropdown.Item>
              </NavDropdown>
            </Nav>
            <Link to="/contactus" className="text-decoration-none">
              <Button variant="" className="d-none d-lg-block" style={{ backgroundColor: '#fa876f' }}>
                Book Now
              </Button>
            </Link>
          </Navbar.Collapse>


        </Navbar>
      </Container>
    </Fragment>
  );
}

export default Index;
